
import { Vue, Component, Emit } from 'vue-property-decorator'
import GlobalMixin from '@/mixins/global'
import configs from '@/configs'
import UAParser from 'ua-parser-js'

@Component({
  name: 'Cxmt',
  mixins: [GlobalMixin]
})
export default class Cxmt extends Vue {
  private isCheck: boolean = false
  private showLogin: boolean = false
  private showToast: boolean = false

  get isFirstAuth () {
    return localStorage.getItem('_Cxmt_First_Auth') || ''
  }

  async created () {
    if (this.isFirstAuth === '1') {
      await this.doAuth()
    } else {
      this.showLogin = true
    }
  }

  checkProtocol () {
    this.isCheck = !this.isCheck
  }

  doAuthStep () {
    if (!this.isCheck) {
      this.showErrorToast()
      return false
    }
    this.doAuth()
  }

  async showErrorToast () {
    if (this.showToast) {
      return
    }
    this.showToast = true
    setTimeout(() => {
      this.showToast = false
    }, 3000)
  }

  isMobileDevice () {
    const parser = new UAParser()
    const result = parser.getResult()
    return result.device.type === 'mobile' || result.device.type === 'tablet'
  }

  @Emit()
  async doAuth () {
    localStorage.setItem('_Cxmt_First_Auth', '1')
    location.replace(configs.authRoot + `connect/cxauth?ChannelTag=ChangXin${this.isMobileDevice() ? '' : 'PC'}`)
  }

  openLink (type: number) {
    if (type === 1) {
      window.open('https://mc.benlai.com/agreement.html?id=297')
    }
    if (type === 2) {
      window.open('https://mc.benlai.com/agreement.html?id=296')
    }
  }
}
